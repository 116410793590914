<script lang="tsx">
import type { SlotsType, Ref } from 'vue'
import type { TabSwitcherOption } from '@core-types/components/CoreUITabSwitcher'
import type { ComponentOverrideOptions } from '@core-types/components'
import { NuxtLink } from '#components'
import { useLink, type UseLinkReturn } from 'vue-router'
import type { NuxtLinkProps } from '#app'

export type CoreUiTabSwitcherNewProps<T> = {
    /**
     * The options to display as tabs.
     */
    options: TabSwitcherOption[]
    /**
     * The ID of the element that describes the tab switcher.
     */
    ariaLabelledby?: string
    /**
     * The label of the tab switcher.
     * Only set this if the tab switcher is not described by an element.
     */
    ariaLabel?: string
    /**
      * Whether to automatically select the option in focus.
      */
    autoSelect?: boolean
    modelValue?: TabSwitcherOption | null
    /**
     * Whether the tab switcher should serve as a navigation bar instead of a tab list.
     * In this mode, the items will be rendered as NuxtLink components.
     * There is no need to adjust the selected options in this mode, since the active link will be highlighted automatically.
     *
     * To adjust whether the active link is highlighted on exact matches only, set this prop to 'exact'.
     * Otherwise, the active link will be highlighted on partial route matches as well. (if the route is '/products'
     * and the link is '/products/1', the link will be highlighted) - This is the default behavior.
     */
    asLinks?: boolean | 'exact' | 'partial'
}

type CoreUiTabSwitcherNewSlots<T> = {
    label: { option: TabSwitcherOption }
}

type ComponentOptions = {}

export function defineComponentCoreUiTabSwitcher<T>(options?: ComponentOverrideOptions<ComponentOptions, CoreUiTabSwitcherNewProps<T>, CoreUiTabSwitcherNewSlots<T>>) {
    return defineComponent(
        (props: CoreUiTabSwitcherNewProps<T>, { emit, slots }) => {
            const selectedOption = ref<TabSwitcherOption | null>(props.modelValue || null)
            const tabs: Ref<HTMLButtonElement[]> = ref([])

            // handle links
            const links = props.asLinks ? new Map<NuxtLinkProps['to'], UseLinkReturn>() : null
            if (props.asLinks) {
                for (const option of props.options) {
                    if (option.to) {
                        links!.set(option.to, useLink({
                            to: option.to,
                        }))
                    }
                }
            }

            /**
             * Whether the provided option is selected.
             * @param option The option to check.
             */
            const isSelected = (option: TabSwitcherOption) => {
                if (props.asLinks && option.to) {
                    const link = links!.get(option.to)

                    if (props.asLinks === 'exact') {
                        return link?.isExactActive.value
                    }

                    return link?.isActive.value
                }
                return selectedOption.value?._htmlId === option._htmlId
            }

            /**
             * Set the selected option.
             * @param option The option to set as selected.
             */
            const setSelectedOption = (option: TabSwitcherOption) => {
                selectedOption.value = option

                emit('update:modelValue', option)
            }

            /**
             * Focus the tab after the provided index.
             * Automatically wraps around to the first tab if the last tab is focused.
             * @param index The index of the reference tab (current one).
             */
            const focusNextTab = (index: number) => {
                const nextIndex = (index + 1) % props.options.length
                focusTab(nextIndex)
            }

            /**
             * Focus the tab before the provided index.
             * Automatically wraps around to the last tab if the first tab is focused.
             * @param index The index of the reference tab (current one).
             */
            const focusPreviousTab = (index: number) => {
                const previousIndex = (index - 1 + props.options.length) % props.options.length
                focusTab(previousIndex)
            }

            /**
             * Focus the tab at the provided index.
             * If `autoSelect` is enabled, the option of the tab will be selected as well.
             * @param index The index of the tab to focus.
             */
            const focusTab = (index: number) => {
                tabs.value[index]!.focus()

                if (props.autoSelect) {
                    setSelectedOption(props.options[index]!)
                }
            }

            /**
             * Handle the keydown event on the tab list.
             * This is used only for the 'Home' and 'End' keys to focus the first and last tab respectively.
             * @param event The keydown event.
             */
            const handleTabListKeydown = (event: KeyboardEvent) => {
                // focus the first tab on 'Home' press
                if (event.key === 'Home') {
                    event.preventDefault()
                    focusTab(0)
                }

                // focus the last tab on 'End' press
                if (event.key === 'End') {
                    event.preventDefault()
                    focusTab(tabs.value.length - 1)
                }
            }

            if (import.meta.dev) {
                if (props.ariaLabelledby && props.ariaLabel) {
                    errorLog('The "aria-label" and "aria-labelledby" props cannot be used together in CoreUiTabSwitcher.', getCurrentInstance()?.vnode.el)
                }
            }

            const rootTag = computed(() => props.asLinks ? 'nav' : 'div')

            return () => (
                <rootTag.value
                    class="sim-tab-switcher"
                    role={props.asLinks ? undefined : 'tablist'}
                    aria-label={props.ariaLabel ?? undefined}
                    aria-labelledby={props.ariaLabelledby ?? undefined}
                    {...{
                        ...(props.asLinks ? {} : {
                            onKeydown: handleTabListKeydown,
                        }),
                    }}
                >
                    {props.options.map((option, index) => props.asLinks
                        ? (
                            <NuxtLink
                                key={option._htmlId}
                                class={['sim-tab-switcher__tab', {
                                    'sim-tab-switcher__tab--active': isSelected(option),
                                }]}
                                to={option.to}
                            >
                                {
                                    renderSlot(slots.label, options?.slots?.label, {
                                        option: option,
                                    }, (
                                        <>
                                            {option.label}
                                        </>
                                    ))
                                }
                            </NuxtLink>
                        )
                        : (
                            <button
                                id={option._htmlId}
                                key={option._htmlId}
                                ref={(el) => tabs.value[index] = el! as HTMLButtonElement}
                                class={['sim-tab-switcher__tab', {
                                    'sim-tab-switcher__tab--active': isSelected(option),
                                }]}
                                role="tab"
                                type="button"
                                aria-selected={isSelected(option)}
                                aria-controls={`${option._htmlId}c`}
                                tabindex={isSelected(option) ? undefined : -1}
                                onClick={() => setSelectedOption(option)}
                                onKeydown={(event) => {
                                    if (event.key === 'ArrowRight') focusNextTab(index)
                                    if (event.key === 'ArrowLeft') focusPreviousTab(index)
                                }}
                            >
                                {
                                    renderSlot(slots.label, options?.slots?.label, {
                                        option: option,
                                    }, (
                                        <>
                                            {option.label}
                                        </>
                                    ))
                                }
                            </button>
                        )
                    )}
                </rootTag.value>
            )
        },
        {
            props: defineRuntimeProps<CoreUiTabSwitcherNewProps<T>>({
                options: { type: Array, required: true },
                ariaLabelledby: { type: String },
                ariaLabel: { type: String },
                autoSelect: { type: Boolean },
                modelValue: { type: Object, default: null },
                // @ts-ignore
                asLinks: { type: [Boolean, String] },
            }),
            slots: Object as SlotsType<CoreUiTabSwitcherNewSlots<T>>,
            emits: ['update:modelValue'],
        }
    )
}

export default defineComponentCoreUiTabSwitcher()
</script>

<style lang="scss" scoped>
@use "@core-scss/components/CoreUiTabSwitcher.scss" as *;

</style>
