<script lang="tsx">
import type { SlotsType } from 'vue'
import type { SizeProp } from '@core-types/components'
import type { BaseFormElementProps } from '@core/app/utils/form'
import { CoreProvideRadioGroup, CoreInternalCheckbox } from '#components'
import type { FormFieldObject } from '@core-types/form'
import type { BaseUiCheckboxExposed } from './BaseUiCheckbox.vue'

// new props
type _BaseUiRatingProps<Sizes extends string, Colors extends string> = {
    value?: number | null
    maxRating?: number
    editable?: boolean
    size?: Sizes
    color?: Colors
    labelId?: string
}

export type BaseUiRatingProps<Sizes extends string, Colors extends string> = _BaseUiRatingProps<Sizes, Colors> & Omit<
    BaseFormElementProps<number | null>,
    'id'
>

export const getBaseUiRatingRuntimeProps = <TProps extends BaseUiRatingProps<any, any>, Sizes extends string, Colors extends string>(options?: ComponentOverrideOptions<any, TProps>)
: RuntimeProps<Pick<TProps, keyof BaseUiRatingProps<any, any>>> => {
    const { id, ...BaseFormElementRuntimeProps } = getBaseFormElementRuntimeProps(Number, options as ComponentOverrideOptions<any, BaseFormElementProps<number>>)

    return {
        ...defineRuntimeProps<_BaseUiRatingProps<Sizes, Colors>>({
            value: { type: Number },
            maxRating: { type: Number, default: 5 },
            editable: { type: Boolean },
            // @ts-ignore
            size: { type: String },
            // @ts-ignore
            color: { type: String },
            labelId: { type: String },
        }, options),
        ...BaseFormElementRuntimeProps,
    }
}

type BaseUiRatingSlots<Sizes extends string, Colors extends string> = {
    default: {}
    item: {
        size: Sizes | undefined
        color: Colors | undefined
        isActive: boolean
    }
}

export interface BaseUiRatingExposed {
    focusActiveRadio: () => void
}

type ComponentOptions = {}

export function defineComponentBaseUiRating<
    Sizes extends string = SizeProp,
    Colors extends string = '',
>(options?: ComponentOverrideOptions<ComponentOptions, BaseUiRatingProps<Sizes, Colors>, BaseUiRatingSlots<Sizes, Colors>>) {
    return defineComponent(
        (props: BaseUiRatingProps<Sizes, Colors>, ctx) => {
            const { t } = useI18n()

            const modelValue = computed<number | null | undefined>({
                get() {
                    return props.modelValue ?? null
                },
                set(val) {
                    if (val === undefined || val === null) return
                    ctx.emit('update:modelValue', val)
                },
            })

            const formModelValue = computed<FormFieldObject<number | null> | undefined>({
                get() {
                    return props.form
                },
                set(val) {
                    if (val === undefined) return
                    ctx.emit('update:form', val)
                },
            })

            const internalValue = computed<number | null>(() => {
                return modelValue.value ?? formModelValue.value?.__v ?? props.value ?? null
            })

            const roundedValue = computed(() => Math.round(internalValue.value ?? 0))

            const isDisabled = computed<boolean>(() => !!(props.loading || props.disabled))

            const radioButtons = useTemplateRefsList<InstanceType<typeof CoreInternalCheckbox>>()

            function focusActiveRadio() {
                const activeRadio = radioButtons.value[Math.max(roundedValue.value - 1, 0)]
                if (!activeRadio) return
                (activeRadio as typeof activeRadio & BaseUiCheckboxExposed).focus()
            }

            ctx.expose({
                focusActiveRadio,
            } satisfies BaseUiRatingExposed)

            return () => (
                <div
                    class={['sim-rating', {
                        'sim-rating--editable': props.editable,
                        'sim-rating--disabled': isDisabled.value,
                        [`sim-rating--c-${props.color}`]: props.color,
                    }]}
                    aria-label={!props.labelId
                        ? props.ariaLabel ? props.ariaLabel : t('_core_simploshop.labels.total_rating', [roundedValue.value, props.maxRating])
                        : undefined
                    }
                    aria-labelledby={props.labelId}
                    role="group"
                >
                    {
                        props.editable
                            ? (
                                <CoreProvideRadioGroup
                                    v-model={modelValue.value}
                                    v-model:form={formModelValue.value}
                                >
                                    {
                                        Array.from({ length: props.maxRating ?? 0 }).map((_, i) => {
                                            const rating = i + 1

                                            return (
                                                <CoreInternalCheckbox
                                                    ref={(el) => radioButtons.value.set(el)}
                                                    class={['sim-rating__item-wrapper', {
                                                        'sim-rating__item-wrapper--active': rating <= roundedValue.value,
                                                    }]}
                                                    value={rating}
                                                    required={props.required}
                                                    disabled={props.disabled}
                                                    descriptionId={props.descriptionId}
                                                    ariaInvalid={props.ariaInvalid}
                                                    ariaLabel={t('_core_simploshop.accessibility.rate_as', [rating, props.maxRating ?? 0])}
                                                    type="radio"
                                                >
                                                    {{
                                                        radio: () => <div class="sim-rating__item">
                                                            {
                                                                renderSlot(ctx.slots.item, options?.slots?.item, {
                                                                    size: props.size,
                                                                    color: props.color,
                                                                    isActive: rating <= roundedValue.value,
                                                                })
                                                            }
                                                        </div>,
                                                    }}
                                                </CoreInternalCheckbox>
                                            )
                                        })
                                    }
                                </CoreProvideRadioGroup>

                            )
                            : Array.from({ length: props.maxRating ?? 0 }).map((_, i) => {
                                const rating = i + 1

                                // NON-EDITABLE RATING
                                return <span
                                    class={['sim-rating__item', {
                                        'sim-rating__item--active': rating <= roundedValue.value,
                                    }]}
                                    aria-hidden="true"
                                >
                                    {
                                        renderSlot(ctx.slots.item, options?.slots?.item, {
                                            size: props.size,
                                            color: props.color,
                                            isActive: rating <= roundedValue.value,
                                        })
                                    }
                                </span>
                            })
                    }
                </div>
            )
        },
        {
            props: getBaseUiRatingRuntimeProps(options),
            slots: Object as SlotsType<BaseUiRatingSlots<Sizes, Colors>>,
            emits: {
                'update:modelValue': (value: number) => true,
                'update:form': (value: FormFieldObject<number | null>) => true,
            },
        }
    )
}

export default defineComponentBaseUiRating()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiRating.scss" as *;

</style>
