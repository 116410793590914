<script lang="tsx">
import type { SlotsType, VNode } from 'vue'
import type { BaseUiRatingProps } from './../BaseUiRating.vue'
import type { _BaseUiFormGroupProps, BaseUiFormGroupSlots } from './BaseUiFormGroup.vue'
import { BaseUiFormGroup, BaseUiRating } from '#components'
import type { SizeProp } from '@core-types/components'
import type { FormFieldObject } from '@core-types/form'
import { type BaseUiRatingExposed, getBaseUiRatingRuntimeProps } from '../BaseUiRating.vue'
import { getBaseUiFormGroupRuntimeProps } from '../form/BaseUiFormGroup.vue'


// new props
type _BaseUiFormRatingProps = {
    /**
     * Where to put the label in relation to the rating component.
     * @default 'vertical'
     */
    labelPosition?: 'vertical' | 'horizontal'
}

export type BaseUiFormRatingProps<Sizes extends string, Colors extends string> =
    _BaseUiFormRatingProps & _BaseUiFormGroupProps & Omit<
        BaseUiRatingProps<Sizes, Colors>, 'editable'
    >

type BaseUiFormRatingSlots = Omit<BaseUiFormGroupSlots, 'default'> & {
    default: {}
}

type ComponentOptions = {}

export function defineComponentBaseUiFormRating<
    Sizes extends string = SizeProp,
    Colors extends string = '',
>(options?: ComponentOverrideOptions<ComponentOptions, BaseUiFormRatingProps<Sizes, Colors>, BaseUiFormRatingSlots>) {

    const { editable, ...BaseUiRatingRuntimeProps } = getBaseUiRatingRuntimeProps(options)

    return defineComponent(
        (props: BaseUiFormRatingProps<Sizes, Colors>, ctx) => {

            const labelId = useId()

            const ratingComp = useTemplateRef<InstanceType<typeof BaseUiRating>>('ratingComponent')

            return () => (
                <BaseUiFormGroup
                    form={props.form}
                    help={props.help}
                    descriptionAbove={props.descriptionAbove}
                    descriptionBelow={props.descriptionBelow}
                    hideRequired={props.hideRequired}
                    label={props.label}
                    noLabelElement={props.noLabelElement}
                    fullWidth={props.fullWidth}
                    errorId={props.errorId}
                    descriptionId={props.descriptionId}
                    disabled={props.disabled}
                    loading={props.loading}
                    required={props.required}
                    ariaLabel={props.ariaLabel}
                    ariaInvalid={props.ariaInvalid}
                >
                    {(slotData: BaseUiFormGroupSlots['default']) => {

                        const label = slotData.renderLabel(
                            (
                                renderSlot(ctx.slots.default, options?.slots?.default, {}, (
                                    <>
                                        {props.label}
                                    </>
                                ))
                            ) as VNode,
                            {
                                hasDefaultSlotContent: ctx.slots.default !== undefined || !!options?.slots?.default,
                                labelId: labelId,
                                onClick: () => {
                                    if (!ratingComp.value) return
                                    (ratingComp.value as typeof ratingComp.value & BaseUiRatingExposed).focusActiveRadio()
                                },
                            }
                        )

                        const ratingComponent = (
                            <BaseUiRating
                                ref="ratingComponent"
                                modelValue={props.modelValue}
                                onUpdate:modelValue={(val: number) => ctx.emit('update:modelValue', val)}
                                modelModifiers={props.modelModifiers}
                                form={props.form}
                                onUpdate:form={(val: FormFieldObject<number | null>) => ctx.emit('update:form', val)}
                                formModifiers={props.formModifiers}

                                labelId={labelId}
                                disabled={props.disabled}
                                loading={props.loading}
                                required={slotData.isInputRequired}
                                descriptionId={slotData.inputDescriptionIds}
                                ariaLabel={props.ariaLabel}
                                ariaInvalid={slotData.isInputAriaInvalid}

                                value={props.value}
                                maxRating={props.maxRating}
                                // @ts-ignore
                                size={props.size}
                                // @ts-ignore
                                color={props.color}
                                editable
                            />
                        )

                        return [
                            ...(props.labelPosition === 'horizontal' ? [] : [label]),

                            slotData.renderAboveDescription(),

                            ...(props.labelPosition === 'horizontal' ? [
                                <div class="sim-rating__container">
                                    {[
                                        ratingComponent,
                                        label,
                                    ]}
                                </div>,
                            ] : [
                                ratingComponent,
                            ]),

                            slotData.renderBelowDescriptionAndError(),
                        ]
                    }}
                </BaseUiFormGroup>
            )
        },
        {
            props: {
                ...defineRuntimeProps<_BaseUiFormRatingProps>({
                    // @ts-ignore
                    labelPosition: { type: String },
                }, options),

                ...BaseUiRatingRuntimeProps,
                ...getBaseUiFormGroupRuntimeProps(options),
            },
            slots: Object as SlotsType<BaseUiFormRatingSlots>,
            emits: {
                'update:modelValue': (value: number) => true,
                'update:form': (value: FormFieldObject<number | null>) => true,
            },
        }
    )
}

export default defineComponentBaseUiFormRating()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiFormRating.scss" as *;

</style>
